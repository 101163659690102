
var inventoryjs = {
    init: function() {
        inventoryjs.displayAssetCategory();
        inventoryjs.displayAssetCategoryForm();
        inventoryjs.bindAssetClick();
        inventoryjs.bindAssetCategoryClick();
        inventoryjs.submitSingleAssetClick();
        inventoryjs.submitDeleteAssetClick();
    },
    displayAssetCategory: function() {
        $('.assetCategorySectionElement').hide();
        $('#assetParentId_' + assetId).show();
    },
    displayAssetCategoryForm: function() {
        $('.assetCategoryFormSectionElement').hide();
        $('#assetCategoryParentId_' + assetCategoryId).show();
    },
    bindAssetClick: function() {
        $('.assetIdRadio').on('click', function(){
            $('.assetIdRadioInput').removeAttr('checked');
            $(this).find('input').attr('checked', 'checked');
            assetId = $(this).find('input').val();
            $('input[name="selectedAssetId"]').val(assetId);
            inventoryjs.displayAssetCategory();
        });
    },
    bindAssetCategoryClick: function() {
        $('.assetCategoryIdRadio').on('click', function(){
            $('.assetCategoryIdRadioInput').removeAttr('checked');
            $(this).find('input').attr('checked', 'checked');
            assetCategoryId = $(this).find('input').val();
            $('input[name="selectedAssetCategoryId"]').val(assetCategoryId);
            inventoryjs.displayAssetCategoryForm();
        });
    },
    submitSingleAssetClick: function() {
        $('.assetUpdateModalSubmit').on('click', function(e) {
            e.preventDefault();

            var form = $(this).closest('form.assetUpdateModalForm');
            var formValues = form.serialize();

            $.post("/inventory/update", formValues, function(data){
                console.log(data.status);
                if (data.status == 'success') {
                    $('.editSucceeded').show();
                    $('.editFailed').hide();
                } else {
                    $('.editSucceeded').hide();
                    $('.editFailed').show();
                }

                setTimeout(() => {
                    location.reload();
                }, 1000);
            });
        });
    },
    submitDeleteAssetClick: function() {
        $('.assetDeleteModalSubmit').on('click', function(e) {
            e.preventDefault();

            var form = $(this).closest('form.assetDeleteModalForm');
            var formValues = form.serialize();

            $.post("/inventory/delete", formValues, function(data){
                $('.deleteMessage').hide();

                if (data.status == 'success') {
                    $('.deleteSucceeded').show();
                    $('.deleteFailed').hide();
                } else {
                    $('.deleteSucceeded').hide();
                    $('.deleteFailed').show();
                }

                setTimeout(() => {
                    location.reload();
                }, 1000);
            });
        });
    }
};

export default inventoryjs;
