
var magnificpopupjs = {
    init: function() {
        $('.image-popup').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            mainClass: 'mfp-no-margins mfp-with-zoom',
             gallery: {
              enabled: true,
              navigateByImgClick: true,
              preload: [0,1]
            },
            image: {
              verticalFit: true
            },
            zoom: {
              enabled: true,
              duration: 300
            }
        });
    }
};

export default magnificpopupjs;
