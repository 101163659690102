
var multistepjs = {
    init: function() {
        if ($('.multistep-form').length > 0) {
            // multistepjs.initializeForm();
            setTimeout(function () { multistepjs.initializeForm() }, 50);
        }
    },

    initializeForm: function() {
        // Display first fieldset
        $('.multistep-form fieldset:first').fadeIn('slow');

        // Reset error
        $('.multistep-form input[type="text"], .multistep-form input[type="radio"], .multistep-form input[type="checkbox"], .multistep-form input[type="password"], .multistep-form input[type="email"], .multistep-form select, .multistep-form textarea').on('focus', function() {
            $(this).removeClass('input-error');

            if ( $(this).is('input:radio') &&  $(this).hasClass('assetIdRadioInput') ) {
                multistepjs.removeError($('.assetIdRadioInput'),false);
            }

            if ( $(this).is('input:radio') &&  $(this).hasClass('assetCategoryIdRadioInput') ) {
                multistepjs.removeError($('.assetCategoryIdRadioInput'),false);
            }

            if ($(this)[0].id == "phone_number") {
                multistepjs.removeError($(this),true);
            }else {
                multistepjs.removeError($(this));
            }
        });

        $('.multistep-form select').on('change', function() {
            $(this).removeClass('input-error');
            multistepjs.removeError($(this),true);
        });

        // Next step
        $('.multistep-form .btn-next').on('click', function() {
            let parent_fieldset = $(this).parents('fieldset');
            let next_step = true;

            // Validate string and not empty
            let isValidString = multistepjs.isValidFieldset(parent_fieldset);
            if(typeof(isValidString) === "boolean"){
                next_step = isValidString;
            }

            // Slide to the next page
            if( next_step ) {
                parent_fieldset.fadeOut(400, function() {
                    $(this).next().fadeIn();
                    //multistepjs.scrollToElement($(this));
                });
            }
        });

        // Previous step
        $('.multistep-form .btn-previous').on('click', function() {
            $(this).parents('fieldset').fadeOut(400, function() {
                $(this).prev().fadeIn();
            });
        });

        // Submit
        $('.multistep-form .multistep-submit').on('click', function(e) {
            e.preventDefault();
            let parent_fieldset = $(this).parents('fieldset').find('.assetCategoryFormSectionElement:visible');
            let next_step = true;

            // Validate string and not empty
            let isValidString = multistepjs.isValidFieldset(parent_fieldset);
            if(typeof(isValidString) === "boolean"){
                next_step = isValidString;
            }

            // Slide to the next page
            if( next_step ) {
                $(this).find('input[type="text"], input[type="password"], input[type="email"], select, textarea').each(function() {
                    $(this).removeClass('input-error');
                });
                // @TODO remove comment to submit the form
                $('#assetForm').submit();
            }
        });

        // Submit
        $('.multistep-form .multistep-testament-submit').on('click', function(e) {
            e.preventDefault();
            let parent_fieldset = $(this).parents('fieldset').find('.attribute-group:visible');
            let next_step = true;

            // Validate string and not empty
            let isValidString = multistepjs.isValidFieldset(parent_fieldset);
            if(typeof(isValidString) === "boolean"){
                next_step = isValidString;
            }

            // Slide to the next page
            if( next_step ) {
                $(this).find('input[type="text"], input[type="password"], input[type="email"], select, textarea').each(function() {
                    $(this).removeClass('input-error');
                });
                // @TODO remove comment to submit the form
                $('#testamentForm').submit();
            }
        });
    },

    errorText: function (fieldName){
        if (appLocale == "fr") {
            return "Le champ "+ fieldName +" est incorrect";
        } else {
            return "The field "+ fieldName + " is incorrect";
        }
    },

    displayError: function (formElement, isSelect = false) {
        if (isSelect) {
            if (!formElement.parent().parent().hasClass('has-error')) {
                formElement.parent().parent().addClass('has-error');
                formElement.parent().parent().append('<span class="invalid-feedback" style="display:block !important">' +
                                            '<strong>'+ multistepjs.errorText(formElement.data('validation')) +'</strong>'+
                                        '</span>');
            }
        } else {
            if (!formElement.parent().hasClass('has-error')) {
                formElement.parent().addClass('has-error');
                formElement.parent().append('<span class="invalid-feedback" style="display:block !important">' +
                                            '<strong>'+multistepjs.errorText(formElement.data('validation'))+'</strong>'+
                                        '</span>');
            }
        }
    },

    removeError: function (formElement, isSelect = false){
        if (isSelect) {
            formElement.parent().parent().children('.invalid-feedback').remove();
            formElement.parent().parent().removeClass('has-error');
        } else {
            formElement.parent().children('.invalid-feedback').remove();
            formElement.parent().removeClass('has-error');
        }
    },

    isValidFieldset: function(fieldset) {
        let isValid = true;

        fieldset.find('input[type="text"], input[type="password"], input[type="email"], input[type="file"], input[type="checkbox"], input[type="radio"], select, textarea').each(function() {
            let fieldValue = $(this).val();
            let isValidElement = true;

            if ($(this).is('input:text')
                && ( ($(this).attr('required') == 'required' && (fieldValue == "" || !/^[a-zA-Z0-9\u00C0-\u024F_:@.,;\/()[\]«»+|"*#{}~\\ &'-]+$/.test(fieldValue)))
                || (fieldValue != "" && !/^[a-zA-Z0-9\u00C0-\u024F_:@.,;\/()[\]«»+|"*#{}~\\ &'-]+$/.test(fieldValue)) )
            ) {
                $(this).addClass('input-error');
                multistepjs.displayError($(this));
                isValid = false;
                isValidElement = false;
            }

            if ( ($(this).is('input:radio') &&  $(this).hasClass('assetIdRadioInput') && !$(".assetIdRadioInput:checked").val())  ) {
                $(this).addClass('input-error');
                multistepjs.displayError($(this));
                isValid = false;
                isValidElement = false;
            }

            if ( ($(this).is('input:radio') &&  $(this).hasClass('assetCategoryIdRadioInput') && !$(".assetCategoryIdRadioInput:checked").val())  ) {
                $(this).addClass('input-error');
                multistepjs.displayError($(this));
                isValid = false;
                isValidElement = false;
            }

            if ($(this).is('textarea')
                && ( ($(this).attr('required') == 'required' && (fieldValue == "" || !/^[a-zA-Z0-9\u00C0-\u024F_\r\n\t:@.,"&\/?!;*=#$€|()~{}[\]`+%«»×÷\\¡¿ '-]+$/.test(fieldValue)))
                || (fieldValue != "" && !/^[a-zA-Z0-9\u00C0-\u024F_\r\n\t:@.,"&\/?!;*=#$€|()~{}[\]`+%«»×÷\\¡¿ '-]+$/.test(fieldValue)) )
            ) {
                $(this).addClass('input-error');
                multistepjs.displayError($(this));
                isValid = false;
                isValidElement = false;
            }

            if ($(this).is('select')
                && ( ($(this).attr('required') == 'required' && (fieldValue == "" || !/^[a-zA-Z0-9\u00C0-\u024F_:@.,/ '-]+$/.test(fieldValue)))
                || (fieldValue != "" && !/^[a-zA-Z0-9\u00C0-\u024F_:@.,/ '-]+$/.test(fieldValue)) )
            ) {
                $(this).addClass('input-error');
                multistepjs.displayError($(this), true);
                $('button[data-id=' + $(this).attr('id') + ']').addClass('input-error');
                isValid = false;
                isValidElement = false;
            }

            if ($(this).is('select') && $(this).hasClass('inputCurrencyElement') ) {
                let inputValueElementContent = $(this).closest('div.assetCategoryFormSectionElement').find('input.inputValueElement:text').val();
                inputValueElementContent = $.trim(inputValueElementContent);

                if (inputValueElementContent.length > 0) {
                    if ( $.trim(fieldValue).length == 0 ) {
                        $(this).addClass('input-error');
                        multistepjs.displayError($(this));
                        isValid = false;
                        isValidElement = false;
                    }
                } else {
                    $(this).removeClass('input-error');
                }
            }

            if ($(this).attr('type') == 'email') {
                let emailRegex  = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;

                if ( ($(this).attr('required') == 'required' && (fieldValue == "" || !emailRegex.test(fieldValue)))
                    || (fieldValue != "" && !emailRegex.test(fieldValue))
                ) {
                    $(this).addClass('input-error');
                    multistepjs.displayError($(this));
                    isValid = false;
                    isValidElement = false;
                } else {
                    $(this).removeClass('input-error');
                }
            }

            if ($(this).hasClass('phone-number')) {
                if (globalIntlTelInput.isValidNumber()) {
                    $(this).removeClass('input-error');
                } else {
                    $(this).addClass('input-error');
                    multistepjs.displayError($(this),true);
                    isValid = false;
                    isValidElement = false;
                }
            }

            if ($(this).hasClass('float-number')) {
                let floatRegex  = /^[0-9]\.[0-9]+$/;

                if ( ($(this).attr('required') == 'required' && (fieldValue == "" || !floatRegex.test(fieldValue)))
                    || (fieldValue != "" && !floatRegex.test(fieldValue))
                ) {
                    $(this).addClass('input-error');
                    multistepjs.displayError($(this));
                    isValid = false;
                    isValidElement = false;
                } else {
                    $(this).removeClass('input-error');
                }
            }

            if ($(this).hasClass('input-url')) {
                //let urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                //let urlRegex = /^((http|https)?:\/\/)?([\w-]+\.)+[\w-]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
                let urlRegex = /^((http|https)?:\/\/)?[\w-]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,18}(:[0-9]{1,5})?(\/.*)?$/
                if ( ($(this).attr('required') == 'required' && (fieldValue == "" || !urlRegex.test(fieldValue)))
                    || (fieldValue != "" && !urlRegex.test(fieldValue))
                ) {
                    $(this).addClass('input-error');
                    multistepjs.displayError($(this));
                    isValid = false;
                    isValidElement = false;
                } else {
                    $(this).removeClass('input-error');
                }
            }

            if ($(this).hasClass('file-upload')) {
                if ( ($(this).attr('required') == 'required' && fieldValue == "")
                    || (fieldValue != "" && $.inArray(fieldValue.split('.').pop(), ['png', 'jpeg', 'jpg', 'PNG', 'JPEG', 'JPG']) === -1 )
                ) {
                    $(this).closest('.custom-file').find('label').addClass('input-error');
                    multistepjs.displayError($(this));
                    isValid = false;
                    isValidElement = false;
                } else {
                    $(this).closest('.custom-file').find('label').removeClass('input-error');
                    multistepjs.removeError($(this));
                }
            }

            if (isValidElement) {
                $(this).removeClass('input-error');

                if ($(this).hasClass('file-upload')) {
                    $(this).closest('.custom-file').find('label').removeClass('input-error');
                    multistepjs.removeError($(this));
                }

                if ($('button[data-id=' + $(this).attr('id') + ']').length > 0) {
                    $('button[data-id=' + $(this).attr('id') + ']').removeClass('input-error');
                }
            }
        });

        return isValid;
    },

    scrollToElement: function() {
        $('html, body').animate({
            scrollTop: element.offset().top
        }, 2000);
    }
};

export default multistepjs;
