/**
 * JQUERY PLUGIN
 */
 require('../vendor/magnific-popup/jquery.magnific-popup.min.js');
 require('../vendor/multistep/js/jquery.backstretch.min.js');
 require('../vendor/bootstrap-select/js/bootstrap-select.min.js');
 require('../vendor/EasyAutocomplete/jquery.easy-autocomplete.min.js');

/**
 * CUSTOM JS
 */
import csrfjs from './common/csrf.js';
import magnificpopupjs from './common/magnificpopup.js';
import multistepjs from './common/multistepjs.js';
import inventoryjs from './common/inventory.js';

$(function(){
    csrfjs.init();
    magnificpopupjs.init();
    multistepjs.init();
    inventoryjs.init();
});